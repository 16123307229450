import { expandBorderRadii } from "@gadgetinc/widgets";
import { monoFontFamily } from "@gadgetinc/widgets/src/EditTheme";
import { styled, useStyletron } from "baseui";
import { omit } from "lodash";
import * as React from "react";
import type { StyleObject } from "styletron-react";

export const Paragraph = (props: JSX.IntrinsicElements["p"]) => {
  const [css, $theme] = useStyletron();
  const inlineStyles = props.style ? css(props.style as StyleObject) : undefined;

  return (
    <p
      data-docscontent="true"
      {...omit(props, "className")}
      className={
        css({
          ...$theme.typography.ParagraphMedium,
          color: $theme.colors.contentPrimary,
          marginBottom: $theme.sizing.scale600,
        }) +
        " " +
        (props.className || "") +
        " " +
        (inlineStyles || "")
      }
    />
  );
};

export const StyledInlineCode = styled("code", ({ $theme }) => ({
  backgroundColor: $theme.colors.primary50,
  fontFamily: monoFontFamily,
  ...$theme.borders.border100,
  color: $theme.colors.contentPrimary,
  paddingTop: "1px",
  paddingBottom: "1px",
  paddingLeft: $theme.sizing.scale200,
  paddingRight: $theme.sizing.scale200,
  ...expandBorderRadii($theme.borders.radius100),
  whiteSpace: "normal",
}));

export const StyledBlockquote = styled("blockquote", ({ $theme }) => ({
  backgroundColor: "rgba(27, 31, 35, 0.03)",
  borderLeftColor: $theme.borders.border200.borderColor,
  borderLeftWidth: $theme.sizing.scale200,
  borderLeftStyle: "solid",
  ...expandBorderRadii("3px"),
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: $theme.sizing.scale700,
  paddingTop: "1em",
  paddingRight: "3em",
  paddingLeft: "3em",
  paddingBottom: "1px",
}));

// wrappers so we don't get react unrecognized props warning from the `styled` components
export const InlineCode = ({ children }: { children: React.ReactNode }) => <StyledInlineCode>{children}</StyledInlineCode>;
export const Blockquote = ({ children }: { children: React.ReactNode }) => <StyledBlockquote>{children}</StyledBlockquote>;

export const Subdued = styled("span", ({ $theme }) => ({
  color: $theme.colors.contentSecondary,
}));
